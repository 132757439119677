import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./SharingRecursiveSwitch.template.vue";

export interface SwitchItem {
  label: string;
  name: string;
  onChange?: (v: boolean) => void;
  conditionalDisable?: () => boolean;
  shouldDisable?: boolean;
  children?: SwitchItem[];
}
@Component({
  name: "SharingRecursiveSwitchComponent",
  mixins: [Template],
  components: {
    SharingRecursiveSwitchComponent
  }
})
export default class SharingRecursiveSwitchComponent extends Vue {
  @Prop({ required: true })
  public model!: Record<string, boolean>;
  @Prop({ required: true })
  public item!: Readonly<SwitchItem>;
  @Prop({ required: false })
  public parent!: Readonly<SwitchItem>;
  @Prop({ default: true }) public sharingSave!: boolean;
  public isLoading = false;

  public updated() {
    this.updateToggle();
  }
  public updateToggle() {
    if (!this.model.product) {
      if (this.model.brand && !this.model.brand_separate_vendor_laboratories) {
        this.model.vendor = true;
      }
    } else {
      if (!this.model.product_separate_product_category) {
        this.model.product_category = true;
      }

      if (!this.model.product_separate_batch_types) {
        this.model.batch_type = true;
      }
      if (!this.model.product_separate_strain) {
        this.model.strain = true;
      }
      if (!this.model.product_separate_price_group) {
        this.model.price_group = true;
      }

      if (
        !this.model.product_separate_brand &&
        !this.model.product_separate_vendor_laboratories
      ) {
        this.model.brand = true;
        this.model.vendor = true;
      } else if (
        this.model.product_separate_brand &&
        this.model.product_separate_vendor_laboratories
      ) {
        if (
          this.model.brand &&
          !this.model.brand_separate_vendor_laboratories
        ) {
          this.model.vendor = true;
        } else {
          return null;
        }
      }

      if (
        this.model.product_separate_vendor_laboratories &&
        !this.model.brand_separate_vendor_laboratories
      ) {
        this.model.brand = true;
        this.model.vendor = true;
      }

      if (
        this.model.product_separate_brand &&
        !this.model.product_separate_vendor_laboratories
      ) {
        this.model.vendor = true;
      } else if (
        !this.model.product_separate_brand &&
        this.model.product_separate_vendor_laboratories
      ) {
        this.model.brand = true;
      }
    }
  }

  public get value() {
    return this.model[this.item.name];
  }

  public onChange(newValue: boolean) {
    // version of the onChange that, in addition to executing the onChange that is passed, calls disableRecursive
    this.model[this.item.name] = newValue;

    // when parent key turned off turning child keys off
    if (
      this.item &&
      this.item.children &&
      this.item.children.length &&
      !this.model[this.item.name]
    ) {
      for (const child of this.item.children) {
        this.model[child.name] = false;
      }
    }

    if (
      this.parent &&
      this.parent.children &&
      this.parent.children.length &&
      !this.model[this.parent.name]
    ) {
      this.isLoading = true;
      for (const child of this.parent.children) {
        this.model[child.name] = false;
      }
    }

    // call stack separation
    setTimeout(() => {
      if (this.item.onChange) {
        this.item.onChange(newValue);
      }
      this.isLoading = false;
    }, 0);
    EventBus.$emit("onChangeOptions", this.item.name, newValue);
  }
}
