import { render, staticRenderFns } from "./SharingRecursiveSwitch.template.vue?vue&type=template&id=691674ba&scoped=true&"
var script = {}
import style0 from "./SharingRecursiveSwitch.template.vue?vue&type=style&index=0&id=691674ba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691674ba",
  null
  
)

export default component.exports