import { policyList } from "@/enums/permissions";
import { Location } from "@/interfaces/location";
import {
  SharingData,
  SharingDataModel,
  SharingLocation
} from "@/interfaces/sharing";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { locationService } from "@/services/location.service";
import { sharingService } from "@/services/sharing.service";
import { PageNavAction } from "@/types/types";
import { BooleanCheck, DynamicFormComponent } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./sharingConfig.template.vue";
import SharingRecursiveSwitchComponent, {
  SwitchItem
} from "./SharingRecursiveSwitch.component";
@Component({
  mixins: [Template],
  components: {
    SharingRecursiveSwitchComponent,
    DynamicFormComponent
  }
})
export default class SharingConfigComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public sharingData: SharingData | null = null;
  public isSaving: boolean = false;
  public loading: boolean = false;
  public sharingSave: boolean = false;
  public isLoading = false;

  public switchesModel: SharingDataModel = {
    active: false,
    product: false,
    product_separate_product_category: false,
    product_separate_batch_types: false,
    product_separate_price_settings: false,
    product_separate_price_group: false,
    product_separate_brand: false,
    product_separate_strain: false,
    product_separate_vendor_laboratories: false,
    batch_type: false,
    strain: false,
    product_category: false,
    vendor: false,
    brand: false,
    brand_separate_vendor_laboratories: false,
    customer: false,
    price_group: false
  };

  public switchesStructure: Readonly<SwitchItem[]> = Object.freeze([
    {
      label: "Active",
      name: "active",
      onChange: newValue => {
        const defaultActivate = [
          "product",
          "batch_type",
          "strain",
          "product_category",
          "vendor",
          "brand",
          "customer",
          "price_group"
        ];
        const shouldActivate = !defaultActivate.some(
          key => this.switchesModel[key]
        );
        if (newValue && shouldActivate) {
          defaultActivate.forEach(key => (this.switchesModel[key] = true));
        }
      },
      children: [
        {
          label: i18n.t("sharing_config.products").toString(),
          name: "product",
          children: [
            {
              label: i18n
                .t("sharing_config.products_separate_product_categories")
                .toString(),
              name: "product_separate_product_category"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_batch_types")
                .toString(),
              name: "product_separate_batch_types"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_price_settings")
                .toString(),
              name: "product_separate_price_settings"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_price_group")
                .toString(),
              name: "product_separate_price_group"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_brands")
                .toString(),
              name: "product_separate_brand"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_strains")
                .toString(),
              name: "product_separate_strain"
            },
            {
              label: i18n
                .t("sharing_config.products_separate_vendors_laboratories")
                .toString(),
              name: "product_separate_vendor_laboratories"
            }
          ]
        },
        {
          label: i18n.t("sharing_config.batch_types").toString(),
          name: "batch_type"
        },
        {
          label: i18n.t("sharing_config.strains").toString(),
          name: "strain"
        },
        {
          label: i18n.t("sharing_config.product_categories").toString(),
          name: "product_category"
        },
        {
          label: i18n.t("sharing_config.vendors_laboratories").toString(),
          name: "vendor"
        },
        {
          label: i18n.t("sharing_config.brands").toString(),
          name: "brand",
          children: [
            {
              label: i18n
                .t("sharing_config.brands_separate_vendors_laboratories")
                .toString(),
              name: "brand_separate_vendor_laboratories"
            }
          ]
        }
      ]
    },
    {
      label: i18n.t("sharing_config.customers").toString(),
      name: "customer",
      conditionalDisable: () => !this.switchesModel.active
    },
    {
      label: i18n.t("sharing_config.price_group").toString(),
      name: "price_group",
      conditionalDisable: () => !this.switchesModel.active
    }
  ]);

  private locationsModel: SharingLocation[] = [];

  public onChangeOptions(keyName: string, keyValue: boolean) {
    if (keyName === "active") {
      this.switchesModel = {
        active: keyValue,
        product: false,
        product_separate_product_category: false,
        product_separate_batch_types: false,
        product_separate_price_settings: false,
        product_separate_price_group: false,
        product_separate_brand: false,
        product_separate_strain: false,
        product_separate_vendor_laboratories: false,
        batch_type: false,
        strain: false,
        product_category: false,
        vendor: false,
        brand: false,
        brand_separate_vendor_laboratories: false,
        customer: false,
        price_group: false
      };
    }
    if (
      this.switchesModel.active === false ||
      this.switchesModel.active === null
    ) {
      if (keyName === "customer" || keyName === "price_group") {
        this.isLoading = true;
        this.switchesModel.customer = false;
        this.switchesModel.price_group = false;
      }
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  public back() {
    this.$router.back();
  }

  public async save() {
    this.isLoading = true;
    const actualModel = this.switchesModel;
    // Reference of how the model to send should be
    // id is what button it refers to, this to later extract it from the model
    // name is to have the name that the server receives / understands
    const sharingReference: Record<
      string,
      {
        separated: Array<{
          id: keyof typeof actualModel;
          name: string;
        }>;
        parent_id: keyof typeof actualModel;
      }
    > = {
      PRODUCT: {
        parent_id: "product",
        separated: [
          {
            id: "product_separate_product_category",
            name: "product_category"
          },
          {
            id: "product_separate_batch_types",
            name: "batch_types"
          },
          {
            id: "product_separate_price_settings",
            name: "price_settings"
          },
          {
            id: "product_separate_price_group",
            name: "price_group"
          },
          {
            id: "product_separate_brand",
            name: "brand"
          },
          {
            id: "product_separate_strain",
            name: "strain"
          },
          {
            id: "product_separate_vendor_laboratories",
            name: "vendor_laboratories"
          }
        ]
      },
      BATCH_TYPE: {
        parent_id: "batch_type",
        separated: []
      },
      STRAIN: {
        parent_id: "strain",

        separated: []
      },
      PRODUCT_CATEGORY: {
        parent_id: "product_category",
        separated: []
      },
      VENDOR: {
        parent_id: "vendor",
        separated: []
      },
      BRAND: {
        parent_id: "brand",
        separated: [
          {
            id: "brand_separate_vendor_laboratories",
            name: "vendor_laboratories"
          }
        ]
      },
      CUSTOMER: {
        parent_id: "customer",
        separated: []
      },
      PRICE_GROUP: {
        parent_id: "price_group",
        separated: []
      }
    };

    // the model to send as such
    const sendingModel: Record<string, { separated: string[] }> = {};

    for (const [key, values] of Object.entries(sharingReference)) {
      // If the parent button is not activated then remove the reference so as not to process it
      if (!actualModel[values.parent_id]) {
        continue;
      }
      sendingModel[key] = {
        separated: []
      };
      // process each separated so that if the button that has id is activated then add it to the final model key
      values.separated.forEach(entity => {
        if (actualModel[entity.id]) {
          sendingModel[key].separated.push(entity.name);
        }
      });
    }

    // send the data to the server
    this.sharingData = await sharingService.updateSharingConfig({
      is_active: !!actualModel.active,
      locations: this.locationsModel,
      entities: sendingModel
    });
    this.onChangeOptions("active", false);

    this.switchesModel.active = this.sharingData!.is_active;

    Object.entries(this.sharingData!.entities).forEach(entity => {
      const property = entity[0].toLowerCase();
      this.switchesModel[property] = true;
      entity[1].separated.forEach(subItem => {
        const subproperty = `${property}_separate_${subItem}`;
        this.switchesModel[subproperty] = true;
      });
    });
    this.isLoading = false;
  }

  protected async mounted() {
    this.sharingSave = this.hasPermission(policyList.modifyDataSharingOptions);
    this.setPageNav({
      title: "sharing.menu_item",
      isLoading: () => this.loading || this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              disabled: this.loading || this.isLoading,
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.back,
            vuetifyProps: () => ({
              disabled: this.isSaving || this.isLoading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.loading = true;
    this.sharingData = await sharingService.getSharing();
    this.switchesModel.active = this.sharingData!.is_active;
    Object.entries(this.sharingData!.entities).forEach(entity => {
      const property = entity[0].toLowerCase();
      this.switchesModel[property] = true;
      entity[1].separated.forEach(subItem => {
        const subproperty = `${property}_separate_${subItem}`;
        this.switchesModel[subproperty] = true;
      });
    });

    this.locationsModel = (await locationService.getAll())
      .filter((l: Location) => l.id !== this.currentLocation.id)
      .map(l => ({
        location_id: Number(l.id),
        is_active: true
      }));
    this.loading = false;
    EventBus.$on("onChangeOptions", (keyName: string, keyValue: boolean) => {
      this.onChangeOptions(keyName, keyValue);
    });
  }
}
