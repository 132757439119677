var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-switch',{attrs:{"value":_vm.value,"disabled":!_vm.sharingSave,"label":_vm.item.label,"hide-details":""},on:{"change":_vm.onChange}}),_vm._v(" "),(_vm.item.children)?_c('div',{staticClass:"container py-0",class:{
      isModifiableOptions:
        (_vm.parent && (_vm.parent.shouldDisable || !_vm.model[_vm.item.name])) ||
        (_vm.item.conditionalDisable && _vm.item.conditionalDisable())
    }},_vm._l((_vm.item.children),function(child,index){return _c('SharingRecursiveSwitchComponent',{key:index,attrs:{"model":_vm.model,"item":child,"parent":Object.assign({}, _vm.item,
        {shouldDisable:
          (_vm.parent && (_vm.parent.shouldDisable || !_vm.model[_vm.parent.name])) ||
          (_vm.item.conditionalDisable && _vm.item.conditionalDisable())}),"sharingSave":_vm.sharingSave}})}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }